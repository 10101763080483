<!--
程序名：问卷预览页面
功能：提供给问卷设计者一个问卷预览
-->
<template>
  <div class="display" v-loading="viewLoading">
    <div class="content">
      <h3>{{ title }}</h3>
      <div class="top" v-if="desc !== ''">
        {{ desc }}
      </div>
      <el-card class="box-card" v-for="(item, index) in detail" :key="index">
        <div slot="header" class="clearfix">

          <div class="questionTitle">
            <!--显示必填标识-->
            <span style="color: #f5222d;">
              <span v-if="item.quesRequired === '1'">*</span>
              <span v-else>&nbsp;</span>
            </span>
            {{ (index + 1) + '.' + item.quesDesc }}
          </div>
        </div>

        <!--单选题展示-->
        <div class="text item" v-if="item.quesType === '1'"
          v-for="(optionItem,optIndex) in (item.quesOption || '').split(',')" :key="optIndex">
          <el-radio v-model="answer[index]" :label="optionItem" style="margin: 5px;">{{ optionItem }}
          </el-radio>
        </div>

        <!--多选题展示-->
        <el-checkbox-group v-if="item.quesType === '2' || item.quesType === '3'" v-model="checkboxs[index]">
          <!-- 如果是可填的选项，需要预留填写答案的输入框 -->
          <div class="text item" v-for="(optionItem, optIndex) in (item.quesOption || '').split(',')" :key="optIndex">
            <el-checkbox :label="optionItem" style="margin: 5px;"></el-checkbox>
            <!-- 这里假设最后一个选项是可填，实际上应该有专门的字段来判断是否可填 -->
            <el-input v-if="item.quesType === '3' && optIndex === (item.quesOption || '').split(',').length - 1"
              v-model="checkboxTexts[index]" resize="none" maxlength="500" style="margin-left: 4%;width: 96%">
            </el-input>
          </div>
        </el-checkbox-group>

        <!--填空题展示-->
        <el-input v-if="item.quesType === '4'" type="textarea" :rows="1" v-model="answer[index]" maxlength="500"
          resize="none">
        </el-input>

      </el-card>

      <div class="bottom">
        <el-link type="info">华讯调研问卷系统&nbsp;提供技术支持</el-link>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    getToken
  } from '@/api/user.js'
  import {
    getQuestionsByPaperId,
  } from '@/api/api';
  export default {
    data() {
      return {
        checkboxTexts: [], // 保存多选框选择其他后填写的文本
        checkboxs: [], // 保存多选框的选项
        answer: [], // 用户的回答
        dialogShow: false,
        dialogTitle: '',
        dialogType: 1, //1添加 2修改
        title: '',
        desc: '',
        detail: [], // 问题列表
        startTimestamp: 0, //填写问卷开始时间戳 毫秒
        viewLoading: false,
      };
    },

    async mounted() {
      var wjId = this.$route.params.id;
      this.viewLoading = true;

      let res = await getQuestionsByPaperId({
        paperId: wjId,
      })
      if (res.code === 200) {
        this.title = res.data.paper.paperTitle;
        this.desc = res.data.paper.paperDesc;
        this.detail = res.data.paper.questions;
        document.title = res.data.paper.paperTitle;
      } else if (res.code === 401) {
        this.toLogin();
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        });
      }
      this.startTimestamp = new Date().getTime(); //时间戳 毫秒

      // 初始化多选框的数组用于保存用户的选项
      for (let index = 0; index < 20; index++) {
        this.checkboxs[index] = [];
      }

      // 显示加载动画
      setTimeout(() => {
        this.viewLoading = false;
      }, 300);
    },
    methods: {
      // token失效，需要重新获取token
      async toLogin() {
        let { code, data, message } = await getToken();
        if (code === 200) {
          sessionStorage.setItem('LocalToken', data.token);
          location.reload();
        } else {
          this.$message({
            type: 'error',
            message: message
          })
        }
      },
    },
  };
</script>
<style scoped>
  .display {
    text-align: center;
    padding: 20px;
  }

  .display .top {
    color: #606266;
    padding: 0 10px 10px 10px;
    border-bottom: 3px solid #36857a;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }

  .display .content {
    width: 100%;
    max-width: 800px;
    display: inline-block;
    text-align: center;
  }

  .display .box-card {
    text-align: left;
    width: 100%;
    margin: 10px 0 10px 0;
  }

  .display .bottom {
    margin: 20px 10px 20px 10px;
    color: #909399;
  }

  .display a:link,
  a:visited,
  a:active {
    color: #909399;
    text-decoration: none;
  }
</style>